import React, { forwardRef, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import { useEffect } from "react";
import { Toast, ToastMessage } from 'primereact/toast';
import usePageVisitStore from "./store/pageVisitStore.ts";

import firebase from 'firebase/compat/app';
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

// Initialize Firebase (replace with your own config)
// const firebaseConfig = {
//   apiKey: "AIzaSyB1X_2s2pc6cFSTPMMZvXjbSnmOlK_K4Aw",
//   authDomain: "demobuild-b784c.firebaseapp.com",
//   projectId: "demobuild-b784c",
//   storageBucket: "demobuild-b784c.appspot.com",
//   messagingSenderId: "301619154736",
//   appId: "1:301619154736:web:1281ae2444f3dc31aa42f5"
// };
// firebase.initializeApp(firebaseConfig);


const ACTIVE_HOUR_START = 8; // Actual start time is 7:30am CST
const ACTIVE_HOUR_END = 18;

function isDuringActiveHours() {
    // Define CST timezone
    const timezone = 'America/Chicago';

    // Get current time in CST
    const now = new Date().toLocaleString('en-US', { timeZone: timezone });

    // Get the hour of the day in CST
    const hour = new Date(now).getHours();

    console.log('hour: ', hour);

    // Check if the hour is within the active hours
    if (hour >= ACTIVE_HOUR_START && hour < ACTIVE_HOUR_END)
    {
        return true;
    } else if (hour === ACTIVE_HOUR_START - 1 && new Date(now).getMinutes() > 30) {
        // Handle the case where the time is between 7:30am and 8am
        return true;
    }
    return false;
}


interface ArcanePlayer {
    play: () => void;
    emitUIEvent: (descriptor: string | object) => boolean;
    onReceiveEvent: (
        name: string,
        listener: (response: string) => void
    ) => void;
    onPlayerEvent: (name: string, listener: (data?: any) => void) => void;
    toggleFullscreen: () => boolean;
}
export interface ArcanePlayerMethods {
    emitUIEvent: (descriptor: string | object) => boolean;
}
export interface ArcanePlayerProps {
    onReceiveEvent?: (response: string) => void;
    onLoading?: () => void;
    onReady?: () => void;
    onAfkWarning?: () => void;
    onAfkWarningDeactivate?: () => void;
    onAfkTimedOut?: () => void;
    mustBeMobile?: boolean;
}

export const ArcanePlayer = forwardRef<ArcanePlayerMethods, ArcanePlayerProps>((props, ref) => {

    const company = process.env.REACT_APP_COMPANY_TO_DEMO; // Check which company this demo is for
    const referralCode = localStorage.getItem('referralCode');

    const standardAnalyticsMetadata = {
        company,
        referralCode,
        hostname: window.location.hostname,
        url: window.location.href,
        userId: firebase.auth().currentUser?.uid,
    };

    const pageVisits = usePageVisitStore(state => state.pageVisits);
    const [hasDeterminedMobile, setHasDeterminedMobile] = useState(false);
    const [slot, setSlot] = useState<string | null>(null);
    const [shouldLoadScript, setShouldLoadScript] = useState(false);
    const [toastMessageToShow, setToastMessageToShow] = useState<ToastMessage | null>(null); // HACK: for some reason, the toast is shown twice on the first load
    const toast = useRef<Toast>(null);

    const showToast = (severity: 'success' | 'info' | 'warn' | 'error' | undefined, summary: string, detail: string, life?: number) => {
        setToastMessageToShow({ severity, summary, detail, life });
    };

    const [showTimeOutDialog, setShowTimeOutDialog] = useState(false);
    const [pageInitialTime, _setPageInitialTime] = useState(Date.now());
    const [isSessionLoading, setIsSessionLoading] = useState(false);
    const [lastActivity, setLastActivity] = useState(Date.now());
    const [isPageActive, setIsPageActive] = useState(true);

    useEffect(() => {
        const handleFocus = () => {
            console.log('focus');
            setIsPageActive(true);
            setLastActivity(Date.now());
        };
        const handleBlur = () => {
            console.log('blur');
            setIsPageActive(false);
        };
        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);
    }, [isPageActive]);

    useEffect(() => {
        // Ensure that only one toast is shown at a time
        if (toastMessageToShow) {
            toast.current?.show(toastMessageToShow);
            setToastMessageToShow(null);
        }
    }, [toastMessageToShow]);

    useEffect(() => {
        console.log("pageVisits: ", pageVisits);
        if (pageVisits > 2) {
            window.location.reload(); // HACK: reload the page to reset the Arcane Player state
        }
    }, [pageVisits]);

    const loadScript = (src: string) => {
        // Check if the script is already loaded
        const existingScript = document.getElementById('arcane-mirage-script');
        if (!existingScript) {
            const script = document.createElement('script');
            script.src = src;
            script.id = 'arcane-mirage-script';
            document.body.appendChild(script);

            script.onload = () => {
                // Script has loaded, you can call any functions that need to run after the script is loaded
                window['initArcanePlayer']();
            };
        }
    }

    useEffect(() => {
        if (shouldLoadScript) {
            loadScript("https://embed.arcanemirage.com/f2e08697-7881-4b00-9823-3372840e507d/e");
        }

        return () => {
            const existingScript = document.getElementById('arcane-mirage-script');
            if (existingScript) {
                existingScript.remove();
            }
        };
    }, [shouldLoadScript]);


    useEffect(() => {

        // Skip the slot assignment if we're still determining if the user is on mobile
        if (!hasDeterminedMobile && !props.mustBeMobile) {
            return;
        }

        const slotsRef = firebase.database().ref('slots');

        const checkAndUpdateSlot = async () => {
            const snapshot = await slotsRef.once('value');
            const slots = snapshot.val();
            const now = Date.now();

            if ((slot == null) && !isDuringActiveHours()) {
                console.log('Not during active hours');
                // GA event logging
                firebase.analytics().logEvent('not_during_active_hours', {
                    sessionStartTime: pageInitialTime,
                    isMobile: isMobile || props.mustBeMobile,
                    ...standardAnalyticsMetadata,
                });
                // Segment event logging
                window.analytics.track('not_during_active_hours', {
                    sessionStartTime: pageInitialTime,
                    isMobile: isMobile || props.mustBeMobile,
                    ...standardAnalyticsMetadata,
                });
                setSlot('none');
                showToast('info', 'After Hours', 'Our demo fast-loading servers are currently only active 7am to 11pm CST. Booting up new server instance. \nThis may take a couple minutes.', 20000);
                return;
            }

            // Check and update only one slot
            if ((slot == null) && (!slots.slot1 || now - slots.slot1 > 10000)) {
                slotsRef.update({ slot1: now });
                // GA event logging
                firebase.analytics().logEvent('slot1_assigned', {
                    sessionStartTime: pageInitialTime,
                    isMobile: isMobile || props.mustBeMobile,
                    ...standardAnalyticsMetadata,
                });
                // Segment event logging
                window.analytics.track('slot1_assigned', {
                    sessionStartTime: pageInitialTime,
                    isMobile: isMobile || props.mustBeMobile,
                    ...standardAnalyticsMetadata,
                });
                setSlot('slot1');
                showToast('success', 'Success', 'You have been assigned to fast-loading server 1', 10000);
                return;
            // } else if ((slot == null) && (!slots.slot2 || now - slots.slot2 > 10000)) {
            //     slotsRef.update({ slot2: now });
            //     // GA event logging
            //     firebase.analytics().logEvent('slot2_assigned', {
            //         sessionStartTime: pageInitialTime,
            //         isMobile: isMobile || props.mustBeMobile,
            //         ...standardAnalyticsMetadata,
            //     });
            //     // Segment event logging
            //     window.analytics.track('slot2_assigned', {
            //         sessionStartTime: pageInitialTime,
            //         isMobile: isMobile || props.mustBeMobile,
            //         ...standardAnalyticsMetadata,
            //     });
            //     setSlot('slot2');
            //     showToast('success', 'Success', 'You have been assigned to fast-loading server 2', 10000);
            //     return;
            } else if (slot == null) {
                // No available slots
                console.log('All fast-loading servers are currently full. You have been assigned to a new instance, which may take a moment to initialize.');
                // GA event logging
                firebase.analytics().logEvent('ondemand_assigned', {
                    sessionStartTime: pageInitialTime,
                    isMobile: isMobile || props.mustBeMobile,
                    ...standardAnalyticsMetadata,
                });
                // Segment event logging
                window.analytics.track('ondemand_assigned', {
                    ondemandLoadTime: Date.now() - pageInitialTime,
                    isMobile: isMobile || props.mustBeMobile,
                    ...standardAnalyticsMetadata,
                });
                setSlot('none');
                showToast('info', 'All Fast Loading Servers are Busy', 'All fast-loading servers are currently full. You have been assigned to a new instance, which may take a moment to initialize.', 20000);
            }

            setShouldLoadScript(true);
        };

        checkAndUpdateSlot();

        const interval = setInterval(() => {
            if (showTimeOutDialog) {
                return;
            }

            if (slot) {
                slotsRef.update({ [slot]: Date.now() });
                // GA event logging
                firebase.analytics().logEvent('slot_updated', {
                    slot,
                    sessionStartTime: pageInitialTime,
                    isMobile: isMobile || props.mustBeMobile,
                    ...standardAnalyticsMetadata,
                });
                // Segment event logging
                window.analytics.track('slot_updated', {
                    slot,
                    sessionStartTime: pageInitialTime,
                    isMobile: isMobile || props.mustBeMobile,
                    ...standardAnalyticsMetadata,
                });
            }
        }, 3000);

        return () => clearInterval(interval);
    }, [slot, hasDeterminedMobile, props.mustBeMobile, showTimeOutDialog]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (showTimeOutDialog) {
                return;
            }

            if (isPageActive || isSessionLoading) {
                console.log('active');
                setLastActivity(Date.now());
            }

            if (lastActivity + 300000 < Date.now()) {
                console.log('timed out');
                // GA event logging
                firebase.analytics().logEvent('session_timed_out', {
                    duration: Date.now() - pageInitialTime,
                    reason: 'page_inactive',
                    sessionStartTime: pageInitialTime,
                    isMobile: isMobile || props.mustBeMobile,
                    ...standardAnalyticsMetadata,
                });
                // Segment event logging
                window.analytics.track('session_timed_out', {
                    duration: Date.now() - pageInitialTime,
                    reason: 'page_inactive',
                    sessionStartTime: pageInitialTime,
                    isMobile: isMobile || props.mustBeMobile,
                    ...standardAnalyticsMetadata,
                });
                setShowTimeOutDialog(true);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [isPageActive, lastActivity, isSessionLoading, showTimeOutDialog]);

    useEffect(() => {
    //   const script = document.createElement('script');
    //   script.src = 'https://embed.arcanemirage.com/f2e08697-7881-4b00-9823-3372840e507d/e';
    //   script.onload = () => {
    //       window['initArcanePlayer']();
    //   };
    //   // @ts-ignore
    //   document.getElementById('root').append(script);

        window.addEventListener('ArcanePlayerLoaded', () => {
            const player: ArcanePlayer = window['ArcanePlayer'];

            player.onPlayerEvent('loading', () => {
                console.log('loading');
                setIsSessionLoading(true);
                if (props.onLoading) {
                    props.onLoading();
                }
            });

            player.onPlayerEvent('ready', () => {
                console.log('ready');
                setIsSessionLoading(false);
                // Segment event logging
                window.analytics.track('arcane_mirage_loaded', {
                    slot: slot,
                    loadTime: Date.now() - pageInitialTime,
                    sessionStartTime: pageInitialTime,
                    isMobile: isMobile || props.mustBeMobile,
                    ...standardAnalyticsMetadata,
                });
                if (slot !== null) {
                    console.log("Calling ResetEverything");
                    emitUIEvent("ResetEverything");
                }
                if (props.mustBeMobile || isMobile) {
                    emitUIEvent("SetToMobileGUI");
                } else {
                    emitUIEvent("SetToDesktopGUI");
                }
                if (props.onReady) {
                    console.log("calling onReady()");
                    props.onReady();
                }
            });

            player.onPlayerEvent('afkWarning', () => {
                console.log('afkWarning');
                if (props.onAfkWarning) {
                    props.onAfkWarning();
                }
            });

            player.onPlayerEvent('afkWarningDeactivate', () => {
                console.log('afkWarningDeactivate');
                if (props.onAfkWarningDeactivate) {
                    props.onAfkWarningDeactivate();
                }
            });

            player.onPlayerEvent('afkTimedOut', () => {
                console.log('afkTimedOut');
                setIsSessionLoading(false);
                setShowTimeOutDialog(true);
                // GA event logging
                firebase.analytics().logEvent('session_timed_out', {
                    duration: Date.now() - pageInitialTime,
                    reason: 'arcane_mirage_timeout',
                    sessionStartTime: pageInitialTime,
                    isMobile: isMobile || props.mustBeMobile,
                    ...standardAnalyticsMetadata,
                });
                // Segment event logging
                window.analytics.track('session_timed_out', {
                    duration: Date.now() - pageInitialTime,
                    reason: 'arcane_mirage_timeout',
                    sessionStartTime: pageInitialTime,
                    isMobile: isMobile || props.mustBeMobile,
                    ...standardAnalyticsMetadata,
                });
                if (props.onAfkTimedOut) {
                    props.onAfkTimedOut();
                }
            });

            // Emit event to Unreal Engine
            // player.emitUIEvent('MyCustomEventWithoutData');
            // player.emitUIEvent({ event: 'MyCustomEventWithData', data: {
            //     foo: 'bar',
            // } });

            // Receive event from Unreal Engine
            player.onReceiveEvent('CustomUIEventResponse', (response) => {
                console.log({ ArcaneResponse: response });
                if (props.onReceiveEvent) {
                    props.onReceiveEvent(response);
                }
            });
            // For starting the stream programatically call:
            //player.play();

            // For entering and exit fullscreen mode, this needs to be called 
            // after the user has any interaction with the site (click/touch or via button)
            // or it will fail
            // returns boolean for the current state of fullscreen element
            //player.toggleFullscreen();

            // Receive file events, only override them if you don't want to use the default button/progress
            player.onPlayerEvent( 'fileProgress', (progress: number) => {
                console.log('File download progress:', progress);
            });

            player.onPlayerEvent( 'fileReceived', (data: {file: Blob, extension: string}) => {
                // Do what you need with the blob, for example, create a hidden anchor tag
                // and download automatically
                const a = document.createElement('a');
                a.setAttribute('href', URL.createObjectURL(data.file));
                a.style.display = 'none';
                a.setAttribute('download', `received_file.${data.extension}`);
                document.body.append(a);
                a.click();
                a.remove();
            });

            console.log("window height: ", window.innerHeight);
        });
    });

    const emitUIEvent = (descriptor: string | object) => {
        const player: ArcanePlayer = window['ArcanePlayer'];
        return player.emitUIEvent(descriptor);
    }

    useImperativeHandle(ref, () => ({
        emitUIEvent,
    }));

    const [isMobile, setIsMobile] = useState(false);
    useLayoutEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        setIsMobile(/mobile|tablet/.test(userAgent));
        setHasDeterminedMobile(true);
    }, []);

    const timeoutDialogFooter = (
        <div>
            <Button onClick={() => window.location.reload()}>Refresh</Button>
        </div>
    );


    // console.log(slot == null)
    return (
    // @ts-ignore
    // <div id="arcane-player"
    //     style={{ width: '100%', height: '100vh' }}
    //     data-project-id="1182"
    //     data-project-key="f2e08697-7881-4b00-9823-3372840e507d"
    //     data-idle-timeout="30"
    //     data-enable-events-passthrough="true"
    //     data-token="Z8oowwmeUfB4"
    // ></div>
    <div style={{width:'100%', height:'calc(100% - 63px)'}}>
        <Toast ref={toast} />
        {/* <button onClick={() => {
            const player: ArcanePlayer = window['ArcanePlayer'];
            player.emitUIEvent('MyCustomEventWithoutData');
            player.emitUIEvent({ event: 'MyCustomEventWithData', data: {
                foo: 'bar',
            } });
        }}>Emit UI Event</button>
        */}
        {/* <button
            onClick={() => {
                emitUIEvent("ResetEverything");
            }}
        >
            Reset
        </button>
        <button
            onClick={() => {
                props.onReady && props.onReady();
            }}
        >
            Calling OnReady again
        </button> */}
        {
            hasDeterminedMobile || props.mustBeMobile ? 
            (
                slot == null ?
                null
                :
                ( 
                    slot == 'none' ?
                    <div id="am-container" 
                        style={{width:'100%', height:'100%'}}>
                        <div id="arcane-player"
                            data-project-id="1182"
                            data-project-key="f2e08697-7881-4b00-9823-3372840e507d"
                            data-idle-timeout="300"
                            data-enable-events-passthrough="true"
                            data-hide-ui-controls="true"
                            data-autoplay="true"
                            data-token="Z8oowwmeUfB4"
                        />
                    </div>
                    :
                    // <div id="am-container" 
                    //     style={{width:'100%', height:'calc(100vh - 63px)'}}>
                    //     <div id="arcane-player"
                    //     data-project-id="1319"
                    //     data-project-key="5cebdfaa-03b5-4330-9b39-71cd2d0298fb"
                    //     data-idle-timeout="60"
                    //     data-enable-events-passthrough="true"
                    //     data-hide-ui-controls="true"
                    //     data-token="25K3vn0T3Li6"
                    //     />            
                    // </div>
                    <div id="am-container" 
                        style={{width:'100%', height:'100%'}}>
                        <div id="arcane-player"
                            // data-project-id="1112"
                            // data-project-key="aa2eb717-24fe-4d9c-901b-3adb9c80ef9c"
                            // data-idle-timeout="60"
                            // data-enable-events-passthrough="true"
                            // data-hide-ui-controls="true"
                            // data-token="Ek7WcA3hSnJE"

                            // data-project-id="1182"
                            // data-project-key="f2e08697-7881-4b00-9823-3372840e507d"
                            // data-idle-timeout="60"
                            // data-enable-events-passthrough="true"
                            // data-hide-ui-controls="true"
                            // data-token="Z8oowwmeUfB4"
                            
                            data-project-id="1319"
                            data-project-key="5cebdfaa-03b5-4330-9b39-71cd2d0298fb"
                            data-idle-timeout="60"
                            data-enable-events-passthrough="true"
                            data-hide-ui-controls="true"
                            data-autoplay="true"
                            data-token="25K3vn0T3Li6"
                        />            
                    </div>
                )
            )
            : 
            <span>has not determined mobile</span>
        }        
        {/* <div id="am-container" 
            style={{width:'100%', height:'calc(100vh - 63px)'}}>
            {
                (isMobile || props.mustBeMobile) ? 
                <div id="arcane-player"
                    data-project-id="1234"
                    data-project-key="03ffae9d-d293-4bb8-8048-7a35a11489c6"
                    data-idle-timeout="300"
                    data-enable-events-passthrough="true"
                    data-hide-ui-controls="true"
                    data-token="Md9YpJV3vXk_"
                />
                : 
                <div id="arcane-player"
                    data-project-id="1182"
                    data-project-key="f2e08697-7881-4b00-9823-3372840e507d"
                    data-idle-timeout="300"
                    data-enable-events-passthrough="true"
                    data-hide-ui-controls="true"
                    data-token="Z8oowwmeUfB4"
                />
            }
            
        </div> */}
        <Dialog
            visible={showTimeOutDialog}
            style={{ width: '50vw' }}
            onHide={() => window.location.reload()}
            footer={timeoutDialogFooter}
        >
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <h1>Session Timed Out</h1>
                <p>Sorry, your session has timed out. Please refresh the page to continue.</p>
            </div>
        </Dialog>
    </div>
    )
    
});
