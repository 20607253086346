import './Navbar.css'
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import useAuthStore from '../store/authStore.ts';
import AuthDialog from "./AuthDialog.tsx";
import { Menu } from 'primereact/menu';

function Navbar(props: {
    logoSrc: string,
    currentRoutePath?: string,
    routeOptions?: Array<{path: string, label: string}>
}) {
    const [showAuthDialog, setShowAuthDialog] = useState(false);
    const user = useAuthStore(state => state.user);
    const logout = useAuthStore(state => state.logout);
    const menuRef = React.useRef<Menu>(null);

    const loggedInMenuOptions = [
        {
            label: 'Log out',
            icon: 'pi pi-fw pi-power-off',
            command: () => {
                logout(()=>{
                    // reload the page to reset the app state
                    window.location.reload();
                });
            }
        },
    ];

    const loggedOutMenuOptions = [
        {
            label: 'Log in',
            icon: 'pi pi-fw pi-sign-in',
            command: () => {
                setShowAuthDialog(true);
            }
        },
    ];

    return (
        <nav className="navbar">
            <div className="navbar__left">
                <img src={props.logoSrc} alt="Company Logo" />
            <div className="navbar__left__routes">
                {props.routeOptions?.map((routeOption, index) => {
                    return (
                        <Link 
                            to={routeOption.path} 
                            key={index} 
                            className={
                                "navbar-route-option" + 
                                (props.currentRoutePath === routeOption.path ? " active" : "")
                            }
                        >
                            {routeOption.label}
                        </Link>
                    )
                })}
            </div>
            </div>
            <div className="navbar__right">
                <Button
                    icon="pi pi-user"
                    severity="secondary"
                    text
                    onClick={(e) => menuRef.current?.toggle(e)}
                />
                <Menu 
                    model={user ? loggedInMenuOptions : loggedOutMenuOptions}
                    popup
                    ref={menuRef}
                />
            </div>
            <AuthDialog 
                visible={showAuthDialog}
                onHide={()=>{
                    setShowAuthDialog(false);
                }}
            />
        </nav>
    );
}

export default Navbar;
