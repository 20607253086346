import React from "react";
import "../DashboardPages.css";
import "./AgentFloorplanPage.css";
import Navbar from "../../components/Navbar.tsx";
import { Badge } from 'primereact/badge'
import { BreadCrumb } from 'primereact/breadcrumb'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import { useParams } from 'react-router-dom'
import { DynamicSvg } from "../../components/DynamicSvg.tsx";
import { useState } from 'react';

export default function AgentFloorplanPage() {

    const company = process.env.REACT_APP_COMPANY_TO_DEMO; // Check which company this demo is for

    const {floorplanId} = useParams<{floorplanId: string}>()

    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    const onPageChange = (event: PaginatorPageChangeEvent) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    return (
        <>
            <Navbar
                logoSrc="/ArchiLabs.png"
                routeOptions={[
                    {
                        path: "/agent/floorplans",
                        label: "Floorplans"
                    },
                    {
                        path: "/agent/customers",
                        label: "Customers"
                    },
                ]}
                currentRoutePath="/agent/floorplans"
            />
            <div className="floorplans-dashboard-header" >
                <h2 className="floorplans-dashboard-header__title">
                    {company == "ubh" ? "Camden Rustic" : "Simple Farmhouse"}
                </h2>
                <div className="floorplans-dashboard-header__actions">
                    <Button
                        label="Request Modifications"
                        severity="secondary"
                        outlined
                    />
                    <Button
                        label="Preview Visualizer"
                    />
                </div>
            </div>
            <div className="floorplans-dashboard-breadcrumb" >
                <BreadCrumb
                    model={[
                        {label: 'Portfolio Floorplan Templates'},
                        {label: company == "ubh" ? 'Camden Rustic' : 'Simple Farmhouse'},
                    ]}
                    home={{icon: 'pi pi-home', url: '/'}}
                />
            </div>
            <div className="floorplan-template-content" >
                <div className="agent-floorplan-page__cover-photo" >
                    <img src="/mock-images/camden-rustic-elevation1.jpg" alt="" />
                </div>
            </div>
            <div className="floorplan-template-content" >
                <h3 className="floorplan-template-content__title" >
                    Customers Who Have Saved This Floorplan
                </h3>
                <div className="floorplan-template-content__search-filter-bar" >
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText placeholder="Search" />
                    </span>
                    <Button
                        label="Filter"
                        icon="pi pi-filter"
                        severity="secondary"
                        outlined
                    />
                </div>
                <div className="floorplan-template-content__customers-list" >
                    <div className="floorplan-template-content__customers-list__customer" >
                        <div className="floorplan-template-content__customers-list__customer__name" >
                            John Smith
                        </div>
                        <div className="floorplan-template-content__customers-list__customer__plans" >
                            <DynamicSvg
                                name="Floorplan"
                                customIconColor="#8896A5"
                                className='floorplan-template-content__customers-list__customer__plans__icon'
                            />
                            <span>3 plans saved</span>
                        </div>
                        <div className="floorplan-template-content__customers-list__customer__date" >
                            2 days ago
                        </div>
                        <Badge />
                    </div>
                    <div className="floorplan-template-content__customers-list__customer" >
                        <div className="floorplan-template-content__customers-list__customer__name" >
                            John Smith
                        </div>
                        <div className="floorplan-template-content__customers-list__customer__plans" >
                            <DynamicSvg
                                name="Floorplan"
                                customIconColor="#8896A5"
                                className='floorplan-template-content__customers-list__customer__plans__icon'
                            />
                            <span>3 plans saved</span>
                        </div>
                        <div className="floorplan-template-content__customers-list__customer__date" >
                            2 days ago
                        </div>
                        <Badge />
                    </div>
                    <div className="floorplan-template-content__customers-list__customer" >
                        <div className="floorplan-template-content__customers-list__customer__name" >
                            John Smith
                        </div>
                        <div className="floorplan-template-content__customers-list__customer__plans" >
                            <DynamicSvg
                                name="Floorplan"
                                customIconColor="#8896A5"
                                className='floorplan-template-content__customers-list__customer__plans__icon'
                            />
                            <span>3 plans saved</span>
                        </div>
                        <div className="floorplan-template-content__customers-list__customer__date" >
                            2 days ago
                        </div>
                        <Badge />
                    </div>
                    <div className="floorplan-template-content__customers-list__customer" >
                        <div className="floorplan-template-content__customers-list__customer__name" >
                            John Smith
                        </div>
                        <div className="floorplan-template-content__customers-list__customer__plans" >
                            <DynamicSvg
                                name="Floorplan"
                                customIconColor="#8896A5"
                                className='floorplan-template-content__customers-list__customer__plans__icon'
                            />
                            <span>3 plans saved</span>
                        </div>
                        <div className="floorplan-template-content__customers-list__customer__date" >
                            2 days ago
                        </div>
                        <Badge />
                    </div>
                </div>
                <Paginator first={first} rows={rows} totalRecords={120} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
            </div>
        </>
    )
}