import React, { useEffect, useState } from "react";
import "../DashboardPages.css";
import "./CustomerFloorplansPage.css";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import Navbar from "../../components/Navbar.tsx";
import { Button } from "primereact/button";
import FloorplanCardHorizontal from "../../components/FloorplanCardHorizontal.tsx";
import CustomizedFloorplanDetailsDialog from "../../components/CustomizedFloorplanDetailsDialog.tsx";
import AuthDialog from "../../components/AuthDialog.tsx";
import useAuthStore from "../../store/authStore.ts";

export default function CustomerFloorplansPage() {

    const company = process.env.REACT_APP_COMPANY_TO_DEMO; // Check which company this demo is for

    const [showFloorplanDetailsDialog, setShowFloorplanDetailsDialog] = React.useState(false);
    const [currentPlanId, setCurrentPlanId] = useState<string | null>(null);
    const [plans, setPlans] = useState<Object>({}); // TODO: type this as Floorplan[]
    const [showAuthDialog, setShowAuthDialog] = useState(false);
    const user = useAuthStore(state => state.user);

    const floorplanDetails = {
        name: company == "ubh" ? "Camden Rustic" : "Simple Farmhouse",
    };

    const createFirebaseListener = () => {
        const userId = firebase.auth().currentUser?.uid;

        if (!userId) {
            console.log("no user id");
            setShowAuthDialog(true);
            return () => {};
        }

        const listener = firebase.database()
            .ref(firebase.auth().currentUser?.uid)
            .child("floorplans")
            .on("value", (snapshot) => {
                if (!snapshot.exists()) {
                    return;
                }
                console.log(snapshot.val());
                setPlans(snapshot.val());
            });
        
        return () => {
            firebase.database()
                .ref(firebase.auth().currentUser?.uid)
                .child("floorplans")
                .off("value", listener);
        }
    }

    useEffect(() => {
        const unsubscribeFirebase = createFirebaseListener();
        return () => unsubscribeFirebase();
    }, [user]);

    return (
        <>
            <AuthDialog 
                visible={showAuthDialog}
                onHide={()=>{
                    setShowAuthDialog(false);
                }}
            />
            <Navbar
                logoSrc={company == "ubh" ? "/ubh-header-logo.svg" : "/ArchiLabs.png"}
                routeOptions={[
                    {
                        path: "/customer/floorplans",
                        label: "Floorplans"
                    },
                ]}
                currentRoutePath="/customer/floorplans"
            />
            <div className="floorplans-dashboard-header" >
                <h2 className="floorplans-dashboard-header__title">
                    My Saved Plans
                </h2>
            </div>
            <div className="customer-floorplans-dashboard__floorplans-list">
                {
                    Object.keys(plans).map((planId) => {
                        return (
                            <FloorplanCardHorizontal
                                key={planId}
                                floorplanName={floorplanDetails.name}
                                onClick={() => {
                                    setCurrentPlanId(planId);
                                }}
                                data={plans[planId]}
                                restrictedWidth 
                            />
                        );
                    })
                }
            </div>
            <CustomizedFloorplanDetailsDialog 
                title={floorplanDetails.name}
                floorplanID={currentPlanId}
                visible={currentPlanId !== null}
                data={currentPlanId ? plans[currentPlanId] : null}
                onHide={() => setCurrentPlanId(null)}
            />
        </>
    );
}