import React from "react";
import "../DashboardPages.css";
import Navbar from "../../components/Navbar.tsx";
import { TabView, TabPanel, TabPanelHeaderTemplateOptions } from 'primereact/tabview';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DynamicSvg } from '../../components/DynamicSvg.tsx';
import FloorplanCardHorizontal from "../../components/FloorplanCardHorizontal.tsx";
import CustomizedFloorplanDetailsDialog from "../../components/CustomizedFloorplanDetailsDialog.tsx";

export default function AgentCustomersPage() {

    const company = process.env.REACT_APP_COMPANY_TO_DEMO; // Check which company this demo is for

    const [showFloorplanDetailsDialog, setShowFloorplanDetailsDialog] = React.useState(false);

    const floorplanDetails = {
        name: company == "ubh" ? "Camden Rustic" : "Simple Farmhouse",
    };

    const mockFloorplanData = {
        "Exterior_Columns": "Dark Wood",
        "Exterior_Masonry": "SlateStone",
        "Exterior_Roof": "Brown",
        "Exterior_Siding": "Green",
        "Exterior_Trim": "Dark_Brown",
        "Material_Kitchen_Bottom_Cabinets": "Dark Gray",
        "Material_Kitchen_Countertop": "White Marble",
        "Material_Kitchen_Tiles": "Black Tiles",
        "Material_Kitchen_Top_Cabinets": "White",
        "Structural_Bath_1": "4ft. x 3ft. Tile Shower",
        "Structural_Fireplace": "fireplace fire",
        "Structural_Garage": "Side Load Garage",
        "Structural_Kitchen_Layout": "Kitchen A",
        "Structural_Porch": "Porch B"
    };
    
    const tabHeaderTemplate = (options: TabPanelHeaderTemplateOptions) => {
        return (
            <button 
                type="button" 
                onClick={options.onClick} 
                className={options.className}
            >
                <div className='p-overlay-badge'>
                    {options.titleElement}
                    <Badge severity="danger" />
                </div>
            </button>
        );
    };


    return (
        <>
            <Navbar
                logoSrc="/ArchiLabs.png"
                routeOptions={[
                    {
                        path: "/agent/floorplans",
                        label: "Floorplans"
                    },
                    {
                        path: "/agent/customers",
                        label: "Customers"
                    },
                ]}
                currentRoutePath="/agent/customers"
            />
            <TabView className="customers-dashboard-tabview">
                <TabPanel header="Leads" headerTemplate={tabHeaderTemplate} >
                    <div className='customers-dashboard-tab-header'>
                        <div>
                            <h2 className="customers-dashboard-tab-header__title">
                                Leads
                            </h2>
                            <span className="customers-dashboard-tab-header__subtitle">
                                Incoming leads from embedded interactive floorplans
                            </span>
                        </div>
                        <div className="customers-dashboard-tab-header__actions">
                            <Button
                                label="Filter"
                                icon="pi pi-filter"
                                severity="secondary"
                                outlined
                            />
                            <Button
                                label="Add New"
                                icon="pi pi-plus"
                            />
                        </div>
                    </div>
                    <div className='customers-dashboard-tab-content'>
                        <div className='customers-dashboard-tab-content__contact-list'>
                            <div className='customers-dashboard-tab-content__contact-list__search-bar'>
                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText placeholder="Search" />
                                </span>
                            </div>
                            <div className='customers-dashboard-tab-content__contact-list__list-item'>
                                <div className='customers-dashboard-tab-content__contact-list__list-item__contact-name'>
                                    <span>John Doe</span>
                                </div>
                                <div className='customers-dashboard-tab-content__contact-list__list-item__date'>
                                    <span>Today</span>
                                </div>
                                <div className='customers-dashboard-tab-content__contact-list__list-item__selected-plans'>
                                    <DynamicSvg
                                        name="Floorplan"
                                        customIconColor="#495057"
                                        className='customers-dashboard-tab-content__contact-list__list-item__selected-plans__icon'
                                    />
                                    <span>2 Selected Plans</span>
                                </div>
                                <div className='customers-dashboard-tab-content__contact-list__list-item__message-preview'>
                                    <span>Hi, I'm interested in this property. Can you please send me more information?</span>
                                </div>
                                <Badge />
                            </div>
                            <div className='customers-dashboard-tab-content__contact-list__list-item selected'>
                                <div className='customers-dashboard-tab-content__contact-list__list-item__contact-name'>
                                    <span>John Doe</span>
                                </div>
                                <div className='customers-dashboard-tab-content__contact-list__list-item__date'>
                                    <span>Today</span>
                                </div>
                                <div className='customers-dashboard-tab-content__contact-list__list-item__selected-plans'>
                                    <DynamicSvg
                                        name="Floorplan"
                                        customIconColor="#495057"
                                        className='customers-dashboard-tab-content__contact-list__list-item__selected-plans__icon'
                                    />
                                    <span>2 Selected Plans</span>
                                </div>
                                <div className='customers-dashboard-tab-content__contact-list__list-item__message-preview'>
                                    <span>Hi, I'm interested in this property. Can you please send me more information?</span>
                                </div>
                                <Badge />
                                <div className="customers-dashboard-tab-content__contact-list__list-item__right-arrow">
                                    <i className="pi pi-chevron-right" />
                                </div>
                            </div>
                            <div className='customers-dashboard-tab-content__contact-list__list-item'>
                                <div className='customers-dashboard-tab-content__contact-list__list-item__contact-name'>
                                    <span>John Doe</span>
                                </div>
                                <div className='customers-dashboard-tab-content__contact-list__list-item__date'>
                                    <span>Today</span>
                                </div>
                                <div className='customers-dashboard-tab-content__contact-list__list-item__selected-plans'>
                                    <DynamicSvg
                                        name="Floorplan"
                                        customIconColor="#495057"
                                        className='customers-dashboard-tab-content__contact-list__list-item__selected-plans__icon'
                                    />
                                    <span>2 Selected Plans</span>
                                </div>
                                <div className='customers-dashboard-tab-content__contact-list__list-item__message-preview'>
                                    <span>Hi, I'm interested in this property. Can you please send me more information?</span>
                                </div>
                                <Badge />
                            </div>
                        </div>
                        <div className='customers-dashboard-tab-content__contact-view'>
                            <div className='customers-dashboard-tab-content__contact-view__header'>
                                <div className='customers-dashboard-tab-content__contact-view__header-left'>
                                    <h3 className='customers-dashboard-tab-content__contact-view__name'>
                                        John Doe
                                    </h3>
                                    <div className='customers-dashboard-tab-content__contact-view__subtitle'>
                                        <i className="pi pi-envelope" />
                                        <div>johndoe@mail.com</div>
                                    </div>
                                    <div className='customers-dashboard-tab-content__contact-view__subtitle'>
                                        <i className="pi pi-phone" />
                                        <span>(555)123-1234</span>
                                    </div>
                                    <div className='customers-dashboard-tab-content__contact-view__subtitle'>
                                        <Badge severity="success" />
                                        <span>Last Activity:  6/20/2023 3:30pm CST</span>
                                    </div>
                                </div>
                                <div className='customers-dashboard-tab-content__contact-view__header-right'>
                                    <Button
                                        label="Convert to Prospect"
                                        icon="pi pi-heart"
                                        iconPos="right"
                                        outlined
                                    />
                                    <Button
                                        label="Options"
                                        icon="pi pi-cog"
                                        severity="secondary"
                                        iconPos="right"
                                        outlined
                                    />
                                    <Button
                                        label="Delete"
                                        icon="pi pi-trash"
                                        severity="danger"
                                        iconPos="right"
                                        outlined
                                    />
                                </div>
                            </div>
                            <div className='customers-dashboard-tab-content__contact-view__floorplans-section'>
                                <h4 className='customers-dashboard-tab-content__contact-view__floorplans-section__header'>
                                    Saved Floorplans:
                                </h4>
                                <FloorplanCardHorizontal 
                                    floorplanName={floorplanDetails.name}
                                    style={{marginBottom: 10}} 
                                    onClick={() => setShowFloorplanDetailsDialog(true)}
                                />
                                <FloorplanCardHorizontal 
                                    floorplanName={floorplanDetails.name}
                                    style={{marginBottom: 10}} 
                                    onClick={() => setShowFloorplanDetailsDialog(true)}
                                />
                            </div>
                        </div>
                    </div>
                    <CustomizedFloorplanDetailsDialog 
                        title={floorplanDetails.name}
                        floorplanID={null}
                        visible={showFloorplanDetailsDialog}
                        onHide={() => setShowFloorplanDetailsDialog(false)}
                        data={mockFloorplanData}
                    />
                </TabPanel>
                <TabPanel header="Prospects" headerTemplate={tabHeaderTemplate} >

                </TabPanel>
                <TabPanel header="Customers" headerTemplate={tabHeaderTemplate} >

                </TabPanel>
            </TabView>
        </>
    )
}