import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import './index.css';
import App from './App.tsx';
import reportWebVitals from './reportWebVitals.ts';
import { ArcanePlayer } from './ArcanePlayer.tsx';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';
import LogInPage from './pages/LogInPage.tsx';
import VisualizerPage from './pages/VisualizerPage.tsx';
import AgentCustomersPage from './pages/agent-views/AgentCustomersPage.tsx';
import AgentFloorplansPage from './pages/agent-views/AgentFloorplansPage.tsx';
import AgentFloorplanPage from './pages/agent-views/AgentFloorplanPage.tsx';
import CustomerFloorplansPage from './pages/customer-views/CustomerFloorplansPage.tsx';
import AuthRoot from './AuthRoot.tsx';
import ReferralCodeCaptureRoot from './utils/ReferralCodeCaptureRoot.tsx';

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <PrimeReactProvider>
        <Router>
        <AuthRoot>
        <ReferralCodeCaptureRoot>
            <Routes>
              <Route path="/" element={<VisualizerPage />} />
              <Route path="/login" element={<LogInPage />} />
              <Route path="/mobile" element={<VisualizerPage isMobile />} />
              <Route path="/visualizer" element={<VisualizerPage />} />
              <Route path="/visualizer/:floorplanId" element={<VisualizerPage />} />
              <Route path="/agent" >
                <Route path="floorplans" element={<AgentFloorplansPage />} />
                <Route path="floorplan/:floorplanId" element={<AgentFloorplanPage />} />
                <Route path="customers" element={<AgentCustomersPage />} />
              </Route>
              <Route path="/customer" >
                <Route path="floorplans" element={<CustomerFloorplansPage />} />
              </Route>
              <Route path="*" element={<div>404</div>} />
            </Routes>
          </ReferralCodeCaptureRoot>
          </AuthRoot>
        </Router>
      </PrimeReactProvider>
  </React.StrictMode>
);


// interface ArcanePlayer {
//   play: () => void;
//   emitUIEvent: (descriptor: string | object) => boolean;
//   onReceiveEvent: (
//       name: string,
//       listener: (response: string) => void
//   ) => void;
//   onPlayerEvent: (name: string, listener: (data?: any) => void) => void;
//   toggleFullscreen: () => boolean;
// }
// const ArcanePlayer = ({ project }) => {
//   useEffect(() => {
//       const script = document.createElement('script');
//       script.src = 'https://embed.arcanemirage.com/f2e08697-7881-4b00-9823-3372840e507d/e';
//       script.onload = () => {
//           window['initArcanePlayer']();
//       };
//       // @ts-ignore
//       document.getElementById('am-container').append(script);

//       window.addEventListener('ArcanePlayerLoaded', () => {
//           const player: ArcanePlayer = window['ArcanePlayer'];

//           player.onReceiveEvent('CustomUIEventResponse', (response) => {
//               console.log({ ArcaneResponse: response });
//           });

//           player.onPlayerEvent('loading', () => {
//               console.log('loading');
//           });

//           player.onPlayerEvent('ready', () => {
//               console.log('ready');
//           });

//           player.onPlayerEvent('afkWarning', () => {
//               console.log('afkWarning');
//           });

//           player.onPlayerEvent('afkWarningDeactivate', () => {
//               console.log('afkWarningDeactivate');
//           });

//           player.onPlayerEvent('afkTimedOut', () => {
//               console.log('afkTimedOut');
//           });
//           // Emit event to Unreal Engine
//           player.emitUIEvent('MyCustomEventWithoutData');
//           player.emitUIEvent({ event: 'MyCustomEventWithData', data: {
//               foo: 'bar',
//           } });
//           // Receive event from Unreal Engine
//           player.onReceiveEvent('CustomUIEventResponse', (response) => {
//               console.log({ ArcaneResponse: response });
//           });
//           // For starting the stream programatically call:
//           player.play();

//           // For entering and exit fullscreen mode, this needs to be called 
//           // after the user has any interaction with the site (click/touch or via button)
//           // or it will fail
//           // returns boolean for the current state of fullscreen element
//           player.toggleFullscreen();

//           // Receive file events, only override them if you don't want to use the default button/progress
//           player.onPlayerEvent( 'fileProgress', (progress: number) => {
//               console.log('File download progress:', progress);
//           } );

//           player.onPlayerEvent( 'fileReceived', (data: {file: Blob, extension: string}) => {
//               // Do what you need with the blob, for example, create a hidden anchor tag
//               // and download automatically
//               const a = document.createElement('a');
//               a.setAttribute('href', URL.createObjectURL(data.file));
//               a.style.display = 'none';
//               a.setAttribute('download', `received_file.${data.extension}`);
//               document.body.append(a);
//               a.click();
//               a.remove();
//           } );
//       });
//   });
//   return project ? (
//           <div
//               id="arcane-player"
//               data-project-id={project.arcaneProjectId}
//               data-project-key={project.arcaneProjectKey}
//               data-idle-timeout="200"
//               data-capture-mouse="false"
//               data-enable-events-passthrough="true"
//               data-hide-ui-controls="true"
//               data-autoplay="false"
//           ></div>
//   ) : null;
// };
// export { ArcanePlayer };

// // @ts-ignore
// ReactDOM.createRoot(document.getElementById('am-container')).render(
//   <ArcanePlayer
//       project={{
//           id: 1182,
//           key: 'f2e08697-7881-4b00-9823-3372840e507d'
//       }}
//   />
// );


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
