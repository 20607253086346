import { create } from 'zustand';
import firebase from 'firebase/compat/app';

export enum UserRole {
    Customer = 'customer',
    Agent = 'agent',
}

interface AuthStore {
    user: firebase.User | null;
    userRole: UserRole | null;
    isAuthenticated: () => boolean;
    login: (user: firebase.User, userRole: UserRole) => void;
    logout: (callback?: ()=>void) => void;    
    subscribeToAuthChanges: (callback?: (user?: firebase.User) => void) => firebase.Unsubscribe;
}

const useAuthStore = create<AuthStore>((set) => ({
    user: null,
    userRole: null,
    isAuthenticated: () => !!(firebase.auth().currentUser),
    login: (user, userRole) => {
        localStorage.setItem('userRole', userRole);
        set({ user, userRole });
    },
    logout: (callback) => {
        firebase.auth().signOut().then(function() {
            // Sign-out successful.
            if (callback) callback();
            }, function(error) {
              // An error happened.
            });
        localStorage.removeItem('userRole');
        set({ user: null, userRole: null });
    },
    subscribeToAuthChanges: (callback) => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                set({ user });
                if (callback) {
                    callback(user);
                }
            } else {
                const { logout } = useAuthStore.getState();
                logout();
            }
        });
        return unsubscribe;
    },
}));

export default useAuthStore;