import React from "react";
import "./FloorplanCardGridBox.css";
import { DynamicSvg } from "./DynamicSvg.tsx";

const ICON_COLOR = "#9FA9B7"

export default function FloorplanCardGridBox(props: {
    title: string;
    onClick?: () => void;
}) {
    return (
        <div className="floorplan-card-grid-box">
            <div className="floorplan-card-grid-box__card" onClick={props.onClick}>
                <div className="floorplan-card-grid-box__card__thumbnail">
                    <img src="/mock-images/camden-rustic-elevation1.jpg" alt="" />
                </div>
                <div className="floorplan-card-grid-box__card__bottom">
                    <div className="floorplan-card-grid-box__card__bottom__left">
                        <div className="floorplan-card-grid-box__card__bottom__left__info-item">
                            <DynamicSvg
                                name="Bed"
                                customIconColor={ICON_COLOR}
                            />
                            <span>
                                {3}
                            </span>
                        </div>
                        <div className="floorplan-card-grid-box__card__bottom__left__info-item">
                            <DynamicSvg
                                name="Bathtub"
                                customIconColor={ICON_COLOR}
                            />
                            <span>
                                {2.5}
                            </span>
                        </div>
                        <div className="floorplan-card-grid-box__card__bottom__left__info-item">
                            <DynamicSvg
                                name="Area"
                                customIconColor={ICON_COLOR}
                            />
                            <span>
                                2,500 sqft
                            </span>
                        </div>
                    </div>
                    <div className="floorplan-card-grid-box__card__bottom__right">
                        <i className="pi pi-ellipsis-h"></i>
                    </div>
                </div>
            </div>
            <h4 className="floorplan-card-grid-box__name">
                {props.title}
            </h4>
            <span className="floorplan-card-grid-box__subtitle">
                Starting at $250,000
            </span>
        </div>
    );
}