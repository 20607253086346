import React, { useLayoutEffect } from "react";
import "./FloorplanCardHorizontal.css"
import { Button } from 'primereact/button';
import { ComputePrice } from "./CustomizedFloorplanDetailsDialog.tsx";

interface FloorplanCardHorizontalProps {
    floorplanName?: string;
    restrictedWidth?: boolean;
    onClick?: () => void;
    style?: any;
    data?: Object | null;
}

export default function FloorplanCardHorizontal(props: FloorplanCardHorizontalProps) {

    const [totalPrice, setTotalPrice] = React.useState<number>(250000);

    useLayoutEffect(() => {
        if (props.data) {
            setTotalPrice(ComputePrice(props.data));
        }
    }, [props.data]);


    return (
        <div className={'floorplan-card-horizontal' + (props.restrictedWidth ? ' restricted-width' : '')}
            onClick={props.onClick}
            style={props.style}
        >
            <div className='floorplan-card-horizontal__image'>
                <img src="/mock-images/camden-rustic-elevation1.jpg" alt="" />
            </div>
            <div className='floorplan-card-horizontal__content'>
                <h5 className='floorplan-card-horizontal__title'>
                    {props.floorplanName}
                </h5>
                <div className='floorplan-card-horizontal__subtitle'>
                    <span>3 Bed | 2 Baths | 1764 sqft</span>
                </div>
                <div className='floorplan-card-horizontal__subtitle'>
                    Configered Price: &nbsp;<span className='floorplan-card-horizontal__subtitle-highlighted'>${totalPrice.toLocaleString()}</span>
                </div>
                <div className='floorplan-card-horizontal__corner-buttons'>
                    <Button
                        icon="pi pi-ellipsis-h"
                        severity="secondary"
                        text
                    />
                </div>
            </div>
        </div>
    );
}