import { create } from 'zustand';

interface FloorplanStore {
    floorplan: Object | null;
    setFloorplan: (floorplan: Object) => void;
    getFloorplan: () => Object | null;
    clearFloorplan: () => void
}

const useFloorplanStore = create<FloorplanStore>((set, get) => ({
    floorplan: null,
    setFloorplan: (floorplan) => set({ floorplan }),
    getFloorplan: () => get().floorplan,
    clearFloorplan: () => set({ floorplan: null })
}));

export default useFloorplanStore;