import React, {useState, useRef, useEffect} from "react";
import "./CustomizedFloorplanDetailsDialog.css";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useNavigate } from 'react-router-dom';


const multiplier=0.35

// HACK: This conversion table is highly fragile and should be replaced with a more robust solution
function gameNameToScreenNameConversion(category: string, choice: string) {
  var screenCategory = "";
  var screenChoice = "";
  switch (category) {
    case "Exterior_Masonry":
      screenCategory = "Masonry";
      screenChoice = choice;
      break;
    case "Exterior_Roof":
      screenCategory = "Roof";
      screenChoice = choice;
      break;
    case "Exterior_Siding":
      screenCategory = "Siding";
      screenChoice = choice;
      break;
    case "Exterior_Trim":
      screenCategory = "Trim";
      if (!choice) {
        // console.log("choice is undefined");
        // console.log("category: ", category);
        // console.log("choice: ", choice);
        break
      }
      screenChoice = choice.replace("_", " ");
      break;
    case "Exterior_Columns":
      screenCategory = "Columns";
      screenChoice = choice;
      break;
    case "Structural_Kitchen_Layout":
      screenCategory = "Kitchen";
      if (choice.includes("Kitchen A"))
        screenChoice = "A";
      else if (choice.includes("B"))
        screenChoice = "B";
      else if (choice.includes("Kitchen С"))
        screenChoice = "C";
      
      break;
    case "Structural_Bath_1":
      screenCategory = "Bath1";
      switch (choice) {
        case "4ft. x 3ft. Tile Shower":
          screenChoice = "Bath 1A";
          break;
        case "4ft. x 4ft. Tile Shower":
          screenChoice = "Bath 1A 4x4";
          break;
        case "Bath 1B 5ft. x 32in. Shower":
          screenChoice = "Bath 1B 5x32 Shower";
          break;
        case "Bath 1C 5ft. x 32in. Shower":
          screenChoice = "Bath 1C 5x32 Shower";
          break;
        default:
          screenChoice = choice;
      }
      break;
    case "Structural_Fireplace":
      screenCategory = "Fireplace";
      switch (choice) {
        case "fireplace off":
          screenChoice = "No";
          break;
        default:
          screenChoice = "Yes";
      }
      break;
    case "Structural_Bath_2":
      screenCategory = "Bath2";
      switch (choice) {
        case "Bath 2a":
          screenChoice = "Bath 2A";
          break;
        case "Bath 2a shower":
          screenChoice = "Bath 2A Shower";
          break;
        case "Bath 2b":
          screenChoice = "Bath 2B";
          break;
        case "Bath 2c":
          screenChoice = "Bath 2C";
          break;
        default:
          screenChoice = choice;
      }
      break;
    case "Structural_Garage":
      screenCategory = "Garage";
      screenChoice = choice;
      break;
    case "Structural_Porch":
      screenCategory = "Porch";
      screenChoice = choice;
      break;
    case "Material_Kitchen_Tiles":
      screenCategory = "Kitchen Tiles";
      screenChoice = choice;
      break;
    case "Material_Kitchen_Countertop":
      screenCategory = "Kitchen Countertop";
      screenChoice = choice;
      break;
    case "Material_Kitchen_Top_Cabinets":
      screenCategory = "Kitchen Top Cabinets";
      screenChoice = choice;
      break;
    case "Material_Kitchen_Bottom_Cabinets":
      screenCategory = "Kitchen Bottom Cabinets";
      screenChoice = choice;
      break;
    default:
      screenCategory = category;
      screenChoice = choice;
  }
  return [screenCategory, screenChoice];
}

function screenCategoryToGameCategoryConversion(category: string) {
  var gameCategory = "";
  switch (category) {
    case "Masonry":
      gameCategory = "Exterior_Masonry";
      break;
    case "Roof":
      gameCategory = "Exterior_Roof";
      break;
    case "Siding":
      gameCategory = "Exterior_Siding";
      break;
    case "Trim":
      gameCategory = "Exterior_Trim";
      break;
    case "Columns":
      gameCategory = "Exterior_Columns";
      break;
    case "Kitchen":
      gameCategory = "Structural_Kitchen_Layout";
      break;
    case "Bath1":
      gameCategory = "Structural_Bath_1";
      break;
    case "Fireplace":
      gameCategory = "Structural_Fireplace";
      break;
    case "Bath2":
      gameCategory = "Structural_Bath_2";
      break;
    case "Garage":
      gameCategory = "Structural_Garage";
      break;
    case "Porch":
      gameCategory = "Structural_Porch";
      break;
    case "Kitchen Tiles":
      gameCategory = "Material_Kitchen_Tiles";
      break;
    case "Kitchen Countertop":
      gameCategory = "Material_Kitchen_Countertop";
      break;
    case "Kitchen Top Cabinets":
      gameCategory = "Material_Kitchen_Top_Cabinets";
      break;
    case "Kitchen Bottom Cabinets":
      gameCategory = "Material_Kitchen_Bottom_Cabinets";
      break;
    default:
      gameCategory = category;
  }
  return gameCategory;
}

const exampleChoices = {
    Kitchen: 'A',
    Bath1: 'Bath 1B',
    Fireplace: "Yes",
    Bath2: 'Bath 2A Shower',
    Garage: 'Porch',
    Porch: 'Porch B Screened',
    Siding: "Green",
    Masonry: "StoneFacade",
    Columns: "Light Wood",
    Trim: "Dark Brown",
    Roof: "Grey",
    "Kitchen Tiles": "Black Tiles",
    "Kitchen Countertop": "White Marble",
    "Kitchen Top Cabinets": "White",
    "Kitchen Bottom Cabinets": "Wood"

  };

const areaOffset = {
    Kitchen: {top: "478", left: "366", width: "575", height: "385"},
    Bath1: {top: "645", left: "935",  width: "271", height: "318"},
    Fireplace: {top: "970", left: "777",  width: "94", height: "121"},
    Bath2: {top: "701", left: "32.5",  width: "341", height: "352"},
    Garage: {top: "386", left: "1190",  width: "531", height: "891"},
    Porch: {top: "150", left: "360",  altLeft: "680", width: "286", height: "327"}
}

const floorplanData = {
  Garage: [
    { name: 'Porch', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FGarage%2FPorch.png?alt=media&token=022ce273-e7ef-49e4-baa6-ee40f8c984cd' },
    { name: 'Carport', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FGarage%2FCarport.png?alt=media&token=5b78f02a-7958-4f79-90c1-a12537bac808' },
    { name: 'Front Load Garage', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FGarage%2FFront%20Load%20Garage.png?alt=media&token=be2f5429-8213-4c0f-aec6-dc1c4943ccb8' },
    { name: 'Front Load Garage w/ Ext Door', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FGarage%2FFront%20Load%20Garage%20w%3AExt%20Door.png?alt=media&token=8c3740e1-e07b-47e8-9f90-b0b263fa1e57' },
    { name: 'Side Load Garage', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FGarage%2FSide%20Load%20Garage.png?alt=media&token=a24fd704-1fc6-44f5-8ee9-c1b4ab737393' },
    { name: 'Side Load Garage w/ Ext Door', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FGarage%2FSide%20Load%20Garage%20w%3AExt%20Door.png?alt=media&token=05f6a317-2ad0-476b-a610-e822641c8e40' },
  ],
  Kitchen: [
    { name: 'A', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FKitchen%2FA.png?alt=media&token=8f03f483-df7c-4c31-bc18-eb60d989674d' },
    { name: 'B', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FKitchen%2FB.png?alt=media&token=4dd1b206-f377-4028-a4ce-bebb2093eb98' },
    { name: 'C', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FKitchen%2FC.png?alt=media&token=bcaf9592-1a22-4df4-ac37-46794d981fed' },
  ],
  Bath1: [
    { name: 'Bath 1A', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FBath1%2F4%20x%203%20ft.%20Tile%20Shower.png?alt=media&token=d367084d-db5f-4370-94ad-0baed2c65524' },
    { name: 'Bath 1A 4x4', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FBath1%2F4%20x%204%20ft.%20Tile%20Shower.png?alt=media&token=e8e3373a-9381-461d-acaf-c1f29916c8d0' },
    { name: 'Bath 1B', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FBath1%2FBath%201B.png?alt=media&token=bbfe51a9-5503-4ba5-adef-2090eabf7626' },
    { name: 'Bath 1B 5x32 Shower', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FBath1%2FBath%201B%205ft.%20x%2032in.%20Shower.png?alt=media&token=3b259ba2-9a2e-4d15-aa6e-9bf043fe4eff' },
    { name: 'Bath 1C', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FBath1%2FBath%201C.png?alt=media&token=32cdd62d-76c9-4868-874d-6ef04929c66b' },
    { name: 'Bath 1C 5x32 Shower', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FBath1%2FBath%201C%205ft.%20x%2032in.%20Shower.png?alt=media&token=0b1c51cd-4f42-4212-a12d-77998944dcdd' },
    { name: 'Bath 1D', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FBath1%2FBath%201D.png?alt=media&token=ba0ab513-a87d-4aac-af0c-d51210c7b8e2' },
  ],
  Fireplace: [
    { name: 'No', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FFireplace%2FNo%20Fireplace.png?alt=media&token=02acafbd-a61b-454d-bb0e-cc1b8b06998c' },
    { name: 'Yes', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FFireplace%2FFireplace.png?alt=media&token=c249a766-de65-4a8d-91c7-3590d1a189f4' },
  ],
  Bath2: [
    { name: 'Bath 2A', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FBath2%2FBath%202A.png?alt=media&token=e529bd37-bf91-4c36-ba07-df7284bedac8' },
    { name: 'Bath 2A Shower', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FBath2%2FBath%202A%20Shower.png?alt=media&token=694e45d0-e616-4144-9edf-8981db00238c' },
    { name: 'Bath 2B', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FBath2%2FBath%202B.png?alt=media&token=93b6d42a-c163-4ad2-a351-7209ce2850d8' },
    { name: 'Bath 2C', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FBath2%2FBath%202C.png?alt=media&token=7e697de3-b07a-49cf-9ac6-505c4962f773' },
  ],
  Porch: [
    { name: 'Porch A', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FPorch%2FPorch%20A.png?alt=media&token=e3109a1a-8d0c-4469-b551-50de0b41bdde' },
    { name: 'Porch B', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FPorch%2FPorch%20B.png?alt=media&token=ce253b82-2fde-4e85-8c30-b6861fa46a43' },
    { name: 'Porch B Screened', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FPorch%2FPorch%20B%20Screened.png?alt=media&token=71d3fa94-bd3c-4df7-b681-8ad6de71a663' },
  ],
};


const generalOptions = {
    Siding: [
        { name: 'Light Brown', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FSiding%2FLight%20Brown.png?alt=media&token=c48bba03-ca2f-45e2-8500-f7227618d8e5' },
    { name: 'Green', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FSiding%2FGreen.png?alt=media&token=8cddc562-1cc0-436a-b23c-2e871fe4bd72' },
    { name: 'Dark Brown', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FSiding%2FDark%20Brown.png?alt=media&token=360dbd15-8bd4-4721-9e95-b7b3fa2174f1' },
        { name: 'White', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FSiding%2FWhite.png?alt=media&token=9433f210-cf17-4458-8800-6053acb19b79' },
        { name: 'Yellow', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FSiding%2FYellow.png?alt=media&token=a3e68cdb-088c-4d69-ba4b-e762f5797510' },
    ],
    Masonry: [
        { name: 'SlateStone', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FMasonry%2FSlateStone.png?alt=media&token=bfbbd965-6afa-4081-b430-fff30e18ecb9' },
        { name: 'StoneFacade', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FMasonry%2FStoneFacade.png?alt=media&token=70b17e6a-1e03-40fc-b6ea-17ed0e40c12d' },
        { name: 'StoneTileFacade', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FMasonry%2FStoneTileFacade.png?alt=media&token=dae62030-4bd4-400f-9ce8-e5e810c43ae4' },
    { name: 'StoneTile', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FMasonry%2FStoneTile.png?alt=media&token=94bdc3b2-86ce-4093-a521-10f1cabf1542' },
    ],
  Columns: [
    { name: 'Light Wood', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FColumns%2FLight%20Wood.png?alt=media&token=88c6f1dd-4085-485c-92b4-71f09b691bba' },
    { name: 'Dark Wood', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FColumns%2FDark%20Wood.png?alt=media&token=d79ca070-9ee0-4bad-ae27-0f8f17d89dbe' },
  ],
  Trim: [
    { name: 'Light Brown', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FTrim%2FLight%20Brown.png?alt=media&token=2338a2c3-dfd8-4447-9093-1320c0e3fcb8' },
    { name: 'Dark Brown', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FTrim%2FDark%20Brown.png?alt=media&token=5767dfa4-209a-49e7-a79c-f33260c555ab' },
    { name: 'White', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FTrim%2FWhite.png?alt=media&token=2e4c7f15-8da5-4a70-978e-87e35a16a3bf' },
    { name: 'Adrift Trim', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FTrim%2FAdrift%20Trim.png?alt=media&token=f3be08fe-5d1b-475e-9cdc-3cea881f64b6' },
  ],
  Roof: [
    { name: 'Grey', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FRoof%2FGrey.png?alt=media&token=f05c453b-fa7b-4470-a774-1460fd54af12' },
    { name: 'Brown', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FRoof%2FBrown.png?alt=media&token=bf3e0994-85c8-458d-93c1-ebb0375c2544' },
  ],
  "Kitchen Tiles": [
    { name: 'Black Tiles', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FKitchen%20Tiles%2FBlack%20Tiles.png?alt=media&token=14fc127f-20e0-43b1-bfcd-42abb3e56295' },
    { name: 'Black and White Tiles', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FKitchen%20Tiles%2FBlack%20and%20White%20Tiles.png?alt=media&token=5bf9ef85-404a-459f-ac7f-e3aeb3a38e1c' },
    { name: 'Marble Tiles', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FKitchen%20Tiles%2FMarble%20Tiles.png?alt=media&token=aa469967-6244-4622-b56e-81d382836319' },
  ],
  "Kitchen Countertop": [
    { name: 'White Marble', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FKitchen%20Countertop%2FWhite%20Marble.png?alt=media&token=b07ca9c1-d7d1-4e41-9505-8113d4d8a6c7' },
    { name: 'Red Marble', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FKitchen%20Countertop%2FRed%20Marble.png?alt=media&token=840a8d07-c980-4c85-8c46-52fa98213452' },
    { name: 'Green Marble', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FKitchen%20Countertop%2FGreen%20Marble.png?alt=media&token=6561e3c6-61a2-4dcf-9f18-7c80711d9be1' },
  ],
  "Kitchen Top Cabinets": [
    { name: 'White', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FKitchen%20Top%20Cabinets%2FWhite.png?alt=media&token=94680432-1c56-4347-b72f-0e626d3642b5' },
    { name: 'Dark Gray', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FKitchen%20Top%20Cabinets%2FDark%20Gray.png?alt=media&token=c5523a6e-048a-4e9c-a590-b75024eba399' },
    { name: 'Wood', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FKitchen%20Top%20Cabinets%2FWood.png?alt=media&token=f12af4c3-ba9c-4701-80c5-dcb08f5ef860' },
  ],
  "Kitchen Bottom Cabinets": [
    { name: 'White', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FKitchen%20Bottom%20Cabinets%2FWhite.png?alt=media&token=eb706910-a7a7-400b-858d-7f3b8ab01272' },
    { name: 'Dark Gray', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FKitchen%20Bottom%20Cabinets%2FDark%20Gray.png?alt=media&token=b6fb38ec-fd04-4468-8a50-699640db9020' },
    { name: 'Wood', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2FKitchen%20Bottom%20Cabinets%2FWood.png?alt=media&token=d3803e0c-aec9-4b6f-b1da-49b57cdf779c' },
  ]
}




/*
Basic explanation of how this is supposed to work:
we have a base image of the floorplan
We have an image for each of the possible customizations within each area (ex. Porch A, Porch B, Porch B Screened)
Suppose the base image is 400px wide x 500px high
Each customization option within an area needs to be the same size (for porch it could be ex. 100px x 100px)
When an option is selected, we place the selected area image inside the original image, offset top/left to its starting position
*/
const FloorPlanCustomizer = (props: { 
    choices: any,
    data: Object | null,
}) => {
  const [selectedOptions, setSelectedOptions] = useState({});

  const handleSelect = (area, imageUrl) => {
    setSelectedOptions(prev => ({ ...prev, [area]: imageUrl }));
  };

  useEffect(() => {
    console.log(selectedOptions);
  }, [selectedOptions]);

  const renderImage = (screenCategory) => {
    if (!(props.data)) return null;
    const item = floorplanData[screenCategory].find(option => {
        if (!(props.data)) return null;
        const gameCategory = screenCategoryToGameCategoryConversion(screenCategory);
        const gameChoice = props.data[gameCategory];
        const [_, screenChoice] = gameNameToScreenNameConversion(gameCategory, gameChoice);

        return (option.name == screenChoice)
    });

    if (!item) return null;
    return <img src={item.imageUrl} alt={screenCategory} width={multiplier*areaOffset[screenCategory].width+'px'} height={multiplier*areaOffset[screenCategory].height+'px'} />;
  };
  if (!(props.data)) return null;
  return (
  <div className="customized-floorplan-details-dialog__panel__body__relative-location">
    <img src="https://firebasestorage.googleapis.com/v0/b/demobuild-b784c.appspot.com/o/Plans%2Fbase_plan.png?alt=media&token=bc0ffbc8-e5a7-403f-85ba-f357b5cdf248" alt="Floor Plan" width={1712*multiplier+'px'} height={1348*multiplier+'px'}/>
    {Object.keys(floorplanData).map((screenCategory, index) => {
      // Calculate adjusted left position for Porch if kitchen choice is 'C'
      if (!(props.data)) return null;
      let adjustedLeft = screenCategory === 'Porch' && props.data['Structural_Kitchen_Layout'] === 'Kitchen С' 
        ? areaOffset[screenCategory].altLeft
        : areaOffset[screenCategory].left;

      return (
        <div key={index} style={{ position: 'absolute', top: `${areaOffset[screenCategory].top*multiplier}px`, left: `${adjustedLeft*multiplier}px` }}>
          {renderImage(screenCategory)}
        </div>
      );
    })}
  </div>
);
};



const GeneralChoices = (props: { 
    choices: any,
    data: Object | null,
}) => {
  const [selectedOptions, setSelectedOptions] = useState({});

  const handleSelect = (area, imageUrl) => {
    setSelectedOptions(prev => ({ ...prev, [area]: imageUrl }));
  };

  useEffect(() => {
    console.log(selectedOptions);
  }, [selectedOptions]);

  const renderImage = (screenCategory) => {
    const item = generalOptions[screenCategory].find(option => {
        if (!(props.data)) return false;
        const gameCategory = screenCategoryToGameCategoryConversion(screenCategory);
        const gameChoice = props.data[gameCategory];
        const [_, screenChoice] = gameNameToScreenNameConversion(gameCategory, gameChoice);

        return (option.name === screenChoice)
    });
    if (!item) return null;
    return (
      <div className="general-choice-card">
        <h5 className="general-choice-title">{screenCategory}</h5>
        <span className="general-choice-title">{item.name}</span>
        <div className="general-choice-image-container">
          <img src={item.imageUrl} alt={screenCategory} className="general-choice-image" />
        </div>
      </div>
    );
  };

  return (
    <div className="general-choices-container">
      {Object.keys(generalOptions).map((area, index) => (
        <div key={index}>
          {renderImage(area)}
        </div>
      ))}
    </div>
  );
};

const ChoicesPage = (props: {
    data: Object | null,
}) => {
    // Example choices, replace with actual data source



    const [pdfView, setpdfView] = useState(false);

    return (
        <div>
            <div>
                <FloorPlanCustomizer choices={exampleChoices} data={props.data} />
                <GeneralChoices choices={exampleChoices} data={props.data} />
            </div>
        </div>
    );
};

export function ComputePrice(
    data: Object | null
) {
    let basePrice = 250000;
    return basePrice + Object.keys(floorplanData).map(screenCategory => {
        if (!(data)) return 0 as number;
        const gameCategory = screenCategoryToGameCategoryConversion(screenCategory);
        const gameChoice = data[gameCategory];
        const [_, screenChoice] = gameNameToScreenNameConversion(gameCategory, gameChoice);

        const options = floorplanData[screenCategory];
        const chosenOption = options.find(option => option.name === screenChoice);
        const isDefaultChoice = options.indexOf(chosenOption) === 0 || !screenChoice; // If no choice is selected, default to first option
        
        if (!isDefaultChoice) {
            return 2000; // Assuming each non-default choice adds $2,000
        }
        return 0;
    }).reduce((a, b) => a + b, 0);
}

export default function CustomizedFloorplanDetailsDialog(props: {
    title: string,
    visible: boolean,
    floorplanID: string | null,
    data: Object | null,
    onHide: () => void
}) {

    const navigate = useNavigate();
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const exportRef = useRef<any>();

    const handleDownloadPdf = async () => {
        const element = exportRef.current;

        try {
            const canvas = await html2canvas(element, { useCORS: true });
            const data = canvas.toDataURL('image/png');

            const pdf = new jsPDF();
            const imgProperties = pdf.getImageProperties(data);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

            pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('choices.pdf');
        } catch (error) {
            console.error("Error generating PDF", error);
        }
    };


    const TotalToAdd = () => {
        const totalPrice = ComputePrice(props.data);
        // let totalPrice = 250000;

        // const renderOptions = () => {
        //     return Object.keys(floorplanData).map(area => {
        //         const choiceName = exampleChoices[area];
        //         const options = floorplanData[area];
        //         const chosenOption = options.find(option => option.name === choiceName);
        //         const isDefaultChoice = options.indexOf(chosenOption) === 0;
                
        //         if (!isDefaultChoice) {
        //             totalPrice += 2000; // Assuming each non-default choice adds $2,000
        //         }

        //         //const priceText = isDefaultChoice ? 'Included' : '+ $2,000';
        //         // other rendering code for options...
        //     });
        // };

        return (
            <div>
                {/* {renderOptions()} */}
                ${totalPrice.toLocaleString()} {/* Formatted totalPrice */}
            </div>
        );
    };
    const FloorplanOptions = () => {
        const renderOptions = () => {
            if (props.data == null) return null;
            return Object.keys(floorplanData).map(screenCategory => {
                if (!(props.data)) return null;
                // const choiceName = exampleChoices[screenCategory];
                const gameCategory = screenCategoryToGameCategoryConversion(screenCategory);
                const gameChoice = props.data[gameCategory];
                const [_, screenChoice] = gameNameToScreenNameConversion(gameCategory, gameChoice);

                const options = floorplanData[screenCategory];
                const chosenOption = options.find(option => option.name === screenChoice);
                const isDefaultChoice = options.indexOf(chosenOption) === 0 || !screenChoice; // If no choice is selected, default to first option
                const priceText = isDefaultChoice ? 'Included' : '+ $2,000';

                return (
                    <div key={screenCategory} className="customized-floorplan-details-dialog__panel__body__list-item">
                    <span>{`${screenCategory} - ${screenChoice ? screenChoice : options[0].name}`}</span>
                    <span className="customized-floorplan-details-dialog__panel__body__list-item__detail-text">
                        <span className={isDefaultChoice ? "" : "purple-text"}>{priceText}</span>
                    </span>
                    </div>
                );
            });
        };

        return (
            <div>
                {renderOptions()}
            </div>
        );
    };


    const DesignOptions = () => {
        const renderOptions = () => {
            if (props.data == null) return null;
            return Object.keys(generalOptions).map(screenCategory => {
                // const choiceName = exampleChoices[area];
                if (!(props.data)) return null;
                const gameCategory = screenCategoryToGameCategoryConversion(screenCategory);
                const gameChoice = props.data[gameCategory];
                const [_, screenChoice] = gameNameToScreenNameConversion(gameCategory, gameChoice);

                const options = generalOptions[screenCategory];
                const chosenOption = options.find(option => option.name === screenChoice);
                const isDefaultChoice = true
                const priceText = isDefaultChoice ? 'Included' : '+ $2,000';

                return (
                    <div key={screenCategory} className="customized-floorplan-details-dialog__panel__body__list-item">
                        <span>{`${screenCategory} - ${screenChoice ? screenChoice : options[0].name}`}</span> {/* HACK: If no choice is selected, default to first option */}
                        <span className="customized-floorplan-details-dialog__panel__body__list-item__detail-text">
                            <span className={isDefaultChoice ? "" : "purple-text"}>{priceText}</span>
                        </span>
                    </div>
                );
            });
        };

        return (
            <div>
                {renderOptions()}
            </div>
        );
    };

    return (
        <Dialog
            header={props.title}
            visible={props.visible}
            onHide={props.onHide}
            style={{ width: '90vw' }}
        >
          <div ref={exportRef}>
            <div className="customized-floorplan-details-dialog__top">
                <div className="customized-floorplan-details-dialog__top__left"
                >
                    <span>Beds: 3 | Baths: 2 | Total SF: 1765 | Heated SF: 1616</span>
                    <span>Configured Price: <b><TotalToAdd/></b></span>
                </div>
                <Button
                    label="3D Visualizer"
                    icon="pi pi-external-link"
                    onClick={() => {
                        // navigate(("/visualizer" + (props.floorplanID ? ("/:" + props.floorplanID) : '')));
                        window.open(("/visualizer" + (props.floorplanID ? ("/" + props.floorplanID) : '')), '_blank', 'noopener,noreferrer');
                    }}
                />
            </div>
            <div className="customized-floorplan-details-dialog__body">
                <div className="customized-floorplan-details-dialog__body__left">
                    <div className="customized-floorplan-details-dialog__panel">
                        <div className="customized-floorplan-details-dialog__panel__header">
                            <span>Structural Options</span>
                        </div>
                        <div className="customized-floorplan-details-dialog__panel__body">
                            <FloorplanOptions/>
                        </div>
                    </div>
                    <div className="customized-floorplan-details-dialog__panel">
                        <div className="customized-floorplan-details-dialog__panel__header">
                            <span>Design Options</span>
                        </div>
                        <div className="customized-floorplan-details-dialog__panel__body">
                            <DesignOptions/>

                        </div>
                    </div>
                </div>
                <div className="customized-floorplan-details-dialog__body__right">
                    <div className="customized-floorplan-details-dialog__panel">
                        <div className="customized-floorplan-details-dialog__panel__header">
                            <span>Customized Floorplan</span>
                        </div>
                        <div className="customized-floorplan-details-dialog__panel__body">
                            <div>
                                <FloorPlanCustomizer choices={exampleChoices} data={props.data} />
                                {/* <ChoicesPage data={props.data} /> */}
                            </div>
                        </div>
                    </div>
                    <div className="customized-floorplan-details-dialog__panel">
                        <div className="customized-floorplan-details-dialog__panel__header">
                            <span>Materials Selections</span>
                        </div>
                        <div className="customized-floorplan-details-dialog__panel__body">
                            <div>
                                <GeneralChoices choices={exampleChoices} data={props.data} />
                                {/* <ChoicesPage data={props.data} /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <Button onClick={handleDownloadPdf}>PDF Export</Button>
        </Dialog>
    );
}