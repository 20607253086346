import React from "react";
import "../DashboardPages.css";
import Navbar from "../../components/Navbar.tsx";
import { Button } from "primereact/button";
import { BreadCrumb } from 'primereact/breadcrumb';
import { DynamicSvg } from "../../components/DynamicSvg.tsx";
import { Menu } from 'primereact/menu';
import { useRef, useState } from "react";
import FloorplanCardGridBox from "../../components/FloorplanCardGridBox.tsx";

// export default function AgentFloorplansPage() {
//     return (
//         <div>
//             <Navbar
//                 logoSrc="/ArchiLabs.png"
//                 routeOptions={[
//                     {
//                         path: "/agent/floorplans",
//                         label: "Floorplans"
//                     },
//                     {
//                         path: "/agent/customers",
//                         label: "Customers"
//                     },
//                 ]}
//                 currentRoutePath="/agent/floorplans"
//             />
//             AgentFloorplansPage
//         </div>
//     );
// }


const ICON_COLOR = "#9FA9B7"

export default function AgentFloorplansPage() {

    const company = process.env.REACT_APP_COMPANY_TO_DEMO; // Check which company this demo is for

    const floorplanTemplateItemOptions = [
        {
            label: 'Delete',
            icon: 'pi pi-times',
            command: () => {}
        },
        {
            label: 'Duplicate',
            icon: 'pi pi-copy',
            command: () => {}
        }
    ]

    const floorplanTemplateItemOptionsMenuRef = useRef<any>(null!);

    const floorplansMockData = [
        {
            id: 1,
            name: 'The Aspen',
            bedrooms: 3,
            bathrooms: 2,
            half_baths: 1,
            stories: 2,
            garage: 2,
            sqft: 2500,
            main_image: '/mock-images/mock-floorplan.png',
            images: [
                '/mock-images/mock-elevation-1.png',
                '/mock-images/mock-elevation-2.png',
            ],
            starting_price: 250000,
        },
        {
            id: 2,
            name: 'The Birch',
            bedrooms: 3,
            bathrooms: 2,
            half_baths: 1,
            stories: 2,
            garage: 2,
            sqft: 2500,
            main_image: '/mock-images/mock-floorplan.png',
            images: [
                '/mock-images/mock-elevation-1.png',
                '/mock-images/mock-elevation-2.png',
            ],
            starting_price: 250000,
        },
        {
            id: 3,
            name: 'The Cedar',
            bedrooms: 3,
            bathrooms: 2,
            half_baths: 1,
            stories: 2,
            garage: 2,
            sqft: 2500,
            main_image: '/mock-images/mock-floorplan.png',
            images: [
                '/mock-images/mock-elevation-1.png',
                '/mock-images/mock-elevation-2.png',
            ],
            starting_price: 250000,
        },
        {
            id: 4,
            name: 'The Dogwood',
            bedrooms: 3,
            bathrooms: 2,
            half_baths: 1,
            stories: 2,
            garage: 2,
            sqft: 2500,
            main_image: '/mock-images/mock-floorplan.png',
            images: [
                '/mock-images/mock-elevation-1.png',
                '/mock-images/mock-elevation-2.png',
            ],
            starting_price: 250000,
        },
    ]

    const [hoveredFloorplanId, setHoveredFloorplanId] = useState<number | null>(null);

    return (
        <>
            <Navbar
                logoSrc="/ArchiLabs.png"
                routeOptions={[
                    {
                        path: "/agent/floorplans",
                        label: "Floorplans"
                    },
                    {
                        path: "/agent/customers",
                        label: "Customers"
                    },
                ]}
                currentRoutePath="/agent/floorplans"
            />
            <div className="floorplans-dashboard-header" >
                <h2 className="floorplans-dashboard-header__title">
                    Floorplan Templates
                </h2>
                <div className="floorplans-dashboard-header__actions">
                    <Button
                        label="Filter"
                        icon="pi pi-filter"
                        severity="secondary"
                        outlined
                    />
                    <Button
                        label="New Plan"
                        icon="pi pi-plus"
                    />
                </div>
            </div>
            <div className="floorplans-dashboard-breadcrumb" >
                <BreadCrumb
                    model={[
                        {label: 'Portfolio Floorplan Templates'},
                    ]}
                    home={{icon: 'pi pi-home', url: '/'}}
                />
            </div>
            <div className="floorplans-dashboard-template-list" >
                {/* {floorplansMockData.map((floorplan, index) => {
                    return (
                        <div 
                            className="floorplans-dashboard-template-list__item"
                            key={index}
                            onClick={() => {
                                window.open(`/agent/floorplan/${floorplan.id}`, '_blank')
                            }}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                floorplanTemplateItemOptionsMenuRef.current.toggle(event)
                            }}
                        >
                            <div className="floorplans-dashboard-template-list__item-card" >
                                <div 
                                    className="floorplans-dashboard-template-list__item__main-image"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        window.open(`/cad`, '_blank')
                                    }}
                                    onMouseEnter={() => {
                                        setHoveredFloorplanId(floorplan.id)
                                    }}
                                    onMouseLeave={() => {
                                        setHoveredFloorplanId(null)
                                    }}
                                >
                                    <img src={floorplan.main_image} alt="" />
                                    {
                                        hoveredFloorplanId === floorplan.id &&
                                        <div className="floorplans-dashboard-template-list__item__main-image__hover">
                                            <span>Open<br/>Visualizer</span>
                                        </div>
                                    }
                                </div>
                                <div className="floorplans-dashboard-template-list__item__top-row">
                                    <Button
                                        icon="pi pi-ellipsis-h"
                                        severity="secondary"
                                        text
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            floorplanTemplateItemOptionsMenuRef.current.toggle(event)
                                        }}
                                    />
                                    <Menu
                                        popup
                                        model={floorplanTemplateItemOptions}
                                        ref={floorplanTemplateItemOptionsMenuRef}
                                    />
                                </div>
                                <div className="floorplans-dashboard-template-list__item__info-left">
                                    <DynamicSvg
                                        name="Bed"
                                        customIconColor={ICON_COLOR}
                                    />
                                    <span>
                                        {floorplan.bedrooms}
                                    </span>
                                </div>
                                <div className="floorplans-dashboard-template-list__item__info-right">
                                    <DynamicSvg
                                        name="Bathtub"
                                        customIconColor={ICON_COLOR}
                                    />
                                    <span>
                                        {floorplan.bathrooms}
                                    </span>
                                </div>
                                <div className="floorplans-dashboard-template-list__item__info-left">
                                    <DynamicSvg
                                        name="Toilet"
                                        customIconColor={ICON_COLOR}
                                    />
                                    <span>
                                        {floorplan.half_baths}
                                    </span>
                                </div>
                                <div className="floorplans-dashboard-template-list__item__info-right">
                                    <DynamicSvg 
                                        name="Stairs-2"
                                        customIconColor={ICON_COLOR}
                                    />
                                    <span>
                                        {floorplan.stories}
                                    </span>
                                </div>
                                <div className="floorplans-dashboard-template-list__item__info-left">
                                    <DynamicSvg
                                        name="Garage"
                                        customIconColor={ICON_COLOR}
                                    />
                                    <span>
                                        {floorplan.garage}
                                    </span>
                                </div>
                                <div className="floorplans-dashboard-template-list__item__info-right">
                                    <DynamicSvg 
                                        name="Area"
                                        customIconColor={ICON_COLOR}
                                    />
                                    <span className="floorplans-dashboard-template-list__item__info__sqft-text">
                                        {floorplan.sqft} ft<sup>2</sup>
                                    </span>
                                </div>
                                <div>
                                    <img src="/mock-images/mock-elevation-1.png" alt="" />
                                </div>
                                <div>
                                    <img
                                        className="floorplans-dashboard-template-list__item__bottom-right-corner" 
                                        src="/mock-images/mock-elevation-2.png" 
                                        alt=""
                                    />
                                </div>
                            </div>
                            <h4 className="floorplans-dashboard-template-list__item-name">
                                {floorplan.name}
                            </h4>
                            <span className="floorplans-dashboard-template-list__item-subtitle">
                                Starting at ${floorplan.starting_price.toLocaleString()}
                            </span>
                        </div>
                    )
                })} */}
                <FloorplanCardGridBox 
                    title={company == "ubh" ? "Camden Rustic" : "Simple Farmhouse"}
                    onClick={() => {
                        window.open(`/agent/floorplan/1`, '_blank')
                    }}
                />
            </div>
        </>
    )
}