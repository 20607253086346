import React from "react";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ReferralCodeCaptureRoot(props: {
    children: React.ReactNode
}) {
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const referralCode = queryParams.get('referral');
        if (referralCode) {
            localStorage.setItem('referralCode', referralCode);
            window.analytics.page({
                referralCode: referralCode
            });
        } else {
            const cachedReferralCode = localStorage.getItem('referralCode');
            window.analytics.page({
                referralCode: cachedReferralCode
            });
        }
        
    }, [location]);

    return (
        <>
            {props.children}
        </>
    );
};