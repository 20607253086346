import React, { useEffect } from "react";
import { ArcanePlayer, ArcanePlayerMethods } from "../ArcanePlayer.tsx";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import AuthDialog from "../components/AuthDialog.tsx";
import { useState } from "react";
import Navbar from "../components/Navbar.tsx";
import { useParams } from "react-router-dom";
import useAuthStore from "../store/authStore.ts";
import useFloorplanStore from "../store/floorplanStore.ts";

export default function VisualizerPage(props: {
    isMobile?: boolean
}) {
    const company = process.env.REACT_APP_COMPANY_TO_DEMO; // Check which company this demo is for
    console.log("company: ", company);
    

    const [showAuthDialog, setShowAuthDialog] = useState(false);
    const [currentPlanId, setCurrentPlanId] = useState<string | null>(null);
    const playerRef = React.useRef<ArcanePlayerMethods>(null);
    //const floorplan = useFloorplanStore(state => state.floorplan);
    const setFloorplan = useFloorplanStore(state => state.setFloorplan);
    const getFloorplan = useFloorplanStore(state => state.getFloorplan);
    
    const user = useAuthStore(state => state.user);
    
    const { floorplanId } = useParams<{floorplanId?: string}>();

    const emitUIEvent = (descriptor: string | object) => {
        playerRef.current?.emitUIEvent(descriptor);
    }

    const createFirebaseListener = () => {
        const userId = firebase.auth().currentUser?.uid;

        if (!userId) {
            console.log("no user id");
            return () => {};
        }

        console.log("creating firebase listener");
        console.log("Floorplan ID: ", floorplanId);
        

        const listener = firebase.database()
            .ref(firebase.auth().currentUser?.uid)
            .child("floorplans")
            .on("value", (snapshot) => {
                const snapshotData = snapshot.val();
                console.log(snapshotData);
                if (snapshotData && floorplanId) {
                    setFloorplan(snapshotData[floorplanId]);
                    console.log("found floorplan");
                }
            });
        
        return () => {
            firebase.database()
                .ref(firebase.auth().currentUser?.uid)
                .child("floorplans")
                .off("value", listener);
        }
    }

    useEffect(() => {
        const unsubscribeFirebase = createFirebaseListener();

        if (floorplanId) {
            setCurrentPlanId(floorplanId);
        }

        return () => unsubscribeFirebase();
    }, [user]);

    const [responseToBeHandled, setResponseToBeHandled] = useState<string | null>(null);

    const handleUEEvent = (response: string) => {
        console.log("handling response: ", response);
        if (response[0] !== '#') {
            console.log("response is not floorplan data");
            return;
        }

        // Strip the response string of '#' prefix and parse it as JSON
        const floorplanDataString = response.substring(1);
        const floorplanData = JSON.parse(floorplanDataString);
        console.log({ floorplanData });

        const userId = firebase.auth().currentUser?.uid;
        if (!userId) {
            console.log("no user id");
            return;
        }
        if (!currentPlanId) {
            firebase.database()
            .ref(firebase.auth().currentUser?.uid)
            .child("floorplans")
            .push(floorplanData)
            .then((snapshot) => {
                console.log(snapshot.key);
                setCurrentPlanId(snapshot.key);
            });
        } else {
            firebase.database()
            .ref(firebase.auth().currentUser?.uid)
            .child("floorplans")
            .child(currentPlanId)
            .set(floorplanData);
        }
        

        // const userId = firebase.auth().currentUser?.uid;
        // if (!userId) {
        //     console.log("no user id");
        //     return;
        // }
        // const floorplanEntriesRef = firebase.database()
        //     .ref(firebase.auth().currentUser?.uid)
        //     .child("floorplans");
        // floorplanEntriesRef.once("value", (snapshot) => {
        //     if (!snapshot.exists()) {
        //         floorplanEntriesRef.push({
        //             "arcaneResponse": response,
        //         });
        //     } else {
        //         floorplanEntriesRef.set({
        //             "arcaneResponse2": response,
        //         });
        //     }
        // });
        setResponseToBeHandled(null);
    }

    const testStoreToFirebase = () => {
        const userId = firebase.auth().currentUser?.uid;
        if (!userId) {
            console.log("no user id");
            return;
        }
        if (!currentPlanId) {
            firebase.database()
            .ref(firebase.auth().currentUser?.uid)
            .child("floorplans")
            .push("test")
            .then((snapshot) => {
                console.log(snapshot.key);
                setCurrentPlanId(snapshot.key);
            });
        } else {
            firebase.database()
            .ref(firebase.auth().currentUser?.uid)
            .child("floorplans")
            .child(currentPlanId)
            .set("test2");
        }
        // floorplanEntriesRef.once("value", (snapshot) => {
        //     if (!snapshot.exists()) {
        //         floorplanEntriesRef.push({
        //             "arcaneResponse": "test",
        //         });
        //     } else {
        //         floorplanEntriesRef.set({
        //             "arcaneResponse2": "test",
        //         });
        //     }
        // });
    }

    const testSettingVariants = () => {
        const variantsJson = {
            "Structural_Kitchen_Tiles": "Black Tiles",
            "Structural_Kitchen_Layout": "Kitchen B",
            "Structural_Fireplace": "fireplace fire",
            "Structural_Bath_2": "Bath 2a",
            "Structural_Garage": "Porch",
            "Structural_Porch": "Porch B",
        }

        emitUIEvent(variantsJson)
    }

    const manuallySetFloorplan = () => {
        const currentFloorplan = getFloorplan();
        if (!currentFloorplan) {
            console.log("no floorplan");
            return;
        }
        emitUIEvent(currentFloorplan);
    }

    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        setIsMobile(/mobile|tablet/.test(userAgent));
    }, []);

    return (
        <div style={{width:'100%', height:'100%'}}>
            <Navbar 
                logoSrc={company == "ubh" ? "/ubh-header-logo.svg" : "/ArchiLabs.png"}
                routeOptions={[
                    {
                        path: "/customer/floorplans",
                        label: "Floorplans"
                    },
                ]}
            />
            {/* VisualizerPage
            <button onClick={()=>setShowAuthDialog(true)}>Show Auth Dialog</button>
            <button onClick={()=>emitUIEvent("test")}>Emit UI Event</button>
            <button onClick={()=>testSettingVariants()}>Test Setting Variants</button>
            <button onClick={()=>testStoreToFirebase()}>Test Store to Firebase</button>
            <button onClick={()=>manuallySetFloorplan()}>Manually Set Floorplan</button> */}
            <AuthDialog 
                visible={showAuthDialog} 
                onHide={()=>{
                    setShowAuthDialog(false)
                    if (responseToBeHandled) {
                        handleUEEvent(responseToBeHandled);
                    }
                }}
            />
            <ArcanePlayer
                onReceiveEvent={(response) => {
                    console.log({ response });
                    // Check if user is logged in first
                    if (!(firebase.auth().currentUser)) {
                        // If not, store the response to be handled after login
                        setResponseToBeHandled(response);
                        setShowAuthDialog(true);
                    } else {
                        handleUEEvent(response);
                    }
                }}
                onLoading={() => {
                    console.log("loading");
                    console.log("plans: ", getFloorplan());
                }}
                onReady={() => {
                    console.log("ready");
                    const currentFloorplan = getFloorplan();
                    console.log("currentFloorplan: ", currentFloorplan);
                    if (currentFloorplan) {
                        setTimeout(() => {
                            emitUIEvent(currentFloorplan);
                        }, 300);
                    }
                }}
                onAfkWarning={() => {
                    console.log("afkWarning");
                }}
                onAfkWarningDeactivate={() => {
                    console.log("afkWarningDeactivate");
                }}
                onAfkTimedOut={() => {
                    console.log("afkTimedOut");
                }}
                mustBeMobile={props.isMobile || isMobile}
                ref={playerRef} 
            />
        </div>
    );
}