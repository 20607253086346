import React, { useEffect, useRef, useState } from 'react';
import './AuthDialog.css'
import firebase from 'firebase/compat/app';
import 'firebase/auth';
import * as firebaseui from 'firebaseui';
import { onAuthStateChanged } from 'firebase/auth';
import { Dialog } from "primereact/dialog";
import useAuthStore, { UserRole } from '../store/authStore.ts'


const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
        {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            fullLabel: "Email Sign In/Sign Up",
        },
        {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            fullLabel: "Gmail Sign In/Sign Up",
        }
    ],
    credentialHelper: 'none',
    callbacks: {
        signInSuccessWithAuthResult: (authResult: any) => {
            console.log("successful sign in");
            return false;
        },
        signInFailure: (error) => {
            console.log(error);
        }
    }
};

function StyledFirebaseAuth(props: {
    uiConfig,
    firebaseAuth, 
    className?: string,
    uiCallback?: (widget: firebaseui.auth.AuthUI) => void,
  }) {
      const { uiConfig, firebaseAuth, className, uiCallback } = props;
      const [userSignedIn, setUserSignedIn] = useState(false);
      const elementRef = useRef(null);
  
      useEffect(() => {
          // Get or Create a firebaseUI instance.
          const firebaseUiWidget = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebaseAuth);
          if (uiConfig.signInFlow === 'popup')
              firebaseUiWidget.reset();
  
          // We track the auth state to reset firebaseUi if the user signs out.
          const unregisterAuthObserver = onAuthStateChanged(firebaseAuth, (user) => {
              if (!user && userSignedIn)
                  firebaseUiWidget.reset();
              setUserSignedIn(!!user);
          });
  
          // Trigger the callback if any was set.
          if (uiCallback)
              uiCallback(firebaseUiWidget);
  
          // Render the firebaseUi Widget.
          // @ts-ignore
          firebaseUiWidget.start(elementRef.current, uiConfig);
  
          return () => {
              unregisterAuthObserver();
              firebaseUiWidget.reset();
          };
      }, [firebaseui, uiConfig]);
  
      return <div className={className} ref={elementRef} />;
  };

interface AuthDialogProps {
    visible: boolean;
    onHide: () => void;
}

export default function AuthDialog(props: AuthDialogProps) {

    const user = useAuthStore(state => state.user);

    useEffect(() => {
        if (user && props.visible) {
            props.onHide();
        }
    }, [user]);

    return (
        <Dialog
            header="Login"
            visible={props.visible}
            onHide={props.onHide}
            style={{ width: '80vw' }}
        >
            <StyledFirebaseAuth 
                uiConfig={uiConfig} 
                firebaseAuth={firebase.auth()} 
                className="firebase-auth-container"
            />
        </Dialog>
    );
}