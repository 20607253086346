import { create } from 'zustand';

interface PageVisitStore {
    pageVisits: number;
    incrementPageVisits: () => void;
}

const usePageVisitStore = create<PageVisitStore>((set, get) => ({
    pageVisits: 0,
    incrementPageVisits: () => set({ pageVisits: get().pageVisits + 1 }),
}));

export default usePageVisitStore;