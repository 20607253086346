import React, { Component, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import './App.css';
import firebase from 'firebase/compat/app';
import "firebase/compat/analytics";
import 'firebase/compat/database';
import useAuthStore from './store/authStore.ts';
import usePageVisitStore from './store/pageVisitStore.ts';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyB1X_2s2pc6cFSTPMMZvXjbSnmOlK_K4Aw",
    authDomain: "demobuild-b784c.firebaseapp.com",
    databaseURL: "https://demobuild-b784c-default-rtdb.firebaseio.com",
    projectId: "demobuild-b784c",
    storageBucket: "demobuild-b784c.appspot.com",
    messagingSenderId: "301619154736",
    appId: "1:301619154736:web:1281ae2444f3dc31aa42f5",
    measurementId: "G-F9LNNRMRPE",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const googleAnalytics = firebase.analytics();

/**
 * Handles the authentication of the user.
 * @param props 
 * @returns 
 */
export default function AuthRoot(props: {
    children: React.ReactNode
}) {

    const subscribeToAuthChanges = useAuthStore(state => state.subscribeToAuthChanges);
    const location = useLocation();
    const incrementPageVisits = usePageVisitStore(state => state.incrementPageVisits);

    useEffect(() => {
        const unsubscribe = subscribeToAuthChanges((user) => {
            if (user) {
                window.analytics.identify(user.uid, {
                    email: user.email,
                    name: user.displayName,
                });
            }
        });
        return () => unsubscribe();
    }, [subscribeToAuthChanges]);

    useEffect(() => {
        incrementPageVisits();
    }, [location, incrementPageVisits]);

    return (
        <>
            {props.children}
        </>
    );
}